<script>
// import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import DetailPotensi from "./detail_potensi.vue";
import { decryptData } from "@/helpers/crypt.js";
export default {
    components: {
        // Datatable,
        Pagination,
        DetailPotensi,
    },
    props: {
        data_jadwal: {
            type: Object,
        },
        data_alattes: {
            type: Object,
        },
    },
    data() {
        let sortOrders = []; // select sortOrder
        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "id_kompetensi_peg" },
            {
                width: "auto",
                label: "NIP",
                name: "nip",
            },
            {
                width: "auto",
                label: "Nama",
                name: "nama_asesi",
            },
            {
                width: "auto",
                label: "Alat Tes",
                name: "-",
            },
            {
                width: "auto",
                label: "Aksi",
                name: "-",
            },
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        // definisi kolom yang dipakai
        let columns2 = [
            { width: "50px", label: "-", name: "-" },
            { width: "50px", label: "No", name: "id_jadwalasesi" },
            {
                width: "auto",
                label: "NIP",
                name: "ja_nip",
            },
            {
                width: "auto",
                label: "Nama",
                name: "ja_nama",
            },
            {
                width: "auto",
                label: "Unit",
                name: "ja_unit",
            },
            {
                width: "auto",
                label: "Jabatan",
                name: "ja_jabatan",
            },
            {
                width: "auto",
                label: "Status",
                name: "status_jadwal_ap",
            },
        ];

        // cek sorting
        columns2.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        return {
            option_asesmen_grade: this.data_jadwal.asesmen_grade,
            selected_id_asesmen_grade: "",
            selected_asesmen_grade: [],

            idjadwal: decryptData(this.$route.params.id),
            selected_nama: "",
            idjadwal_peserta: "",
            table_data: [],
            loadingTable: true,
            is_process: false,
            columns: columns,
            sortKey: "id_role", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",
            selected_peserta: [],
            sessionRoleId: localStorage.session_role_active_id,
            syncData: {
                idjadwal: "",
                lastSync: "",
            },
            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                idjadwal: this.$route.params.id,
                role_id: localStorage.session_role_active_id,
                username: localStorage.session_username,
                m_dirkom_idm_dirkom: "",
            },
            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
            checkedAll: false,

            path_file_import: "",

            showDetailPotensi: false,
            selected_nip: "",

            selected_jobtarget: "",
            option_jobtarget: [],

            data_detail: []
        };
    },
    mounted() {
        let self = this;

        this.tableData.idjadwal = decryptData(this.$route.params.id);
        self.getDataTable();
    },
    created() {
        this.searchDatatable = debounce(() => {
            this.getDataTable();
            // call fetch API to get results
        }, 500);
    },
    methods: {
        filteredAlatTesData(alat_tes_data, m_alat_tes_id) {
            // Parse the JSON data and filter it
            if (alat_tes_data) {
                let filteredAlatTes = alat_tes_data.filter(alat_tes => {
                    return alat_tes.m_alat_tes_id === m_alat_tes_id;
                });
                if (filteredAlatTes.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }


        },
        searchJobTarget: function (search) {
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/job-target-jadwal",
                params: {
                    keyword: search,
                    id_jadwal: self.idjadwal
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config).then((response) => {
                if (response.data.meta.code == 200) {
                    self.option_jobtarget = response.data.data.referensi;
                }
            });
        },
        onChangeJobTarget() {
            this.tableData.id_job_target = this.selected_jobtarget.m_jobtarget_id;
            this.getDataTable();
        },
        configPagination(data) {
            let self = this;
            self.pagination.lastPage = data.last_page;
            self.pagination.currentPage = data.current_page;
            self.pagination.total = data.total;
            self.pagination.lastPageUrl = data.last_page_url;
            self.pagination.nextPageUrl = data.next_page_url;
            self.pagination.prevPageUrl = data.prev_page_url;
            self.pagination.from = data.from;
            self.pagination.to = data.to;
            self.pagination.links = data.links;
        },
        sortBy(key) {
            let self = this;
            self.sortKey = key;
            self.sortOrders[key] = self.sortOrders[key] * -1;
            self.tableData.column = self.getIndex(self.columns, "name", key);
            self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
            self.getDataTable();
        },
        getIndex(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },
        toPage: function (str) {
            let self = this;
            self.getDataTable(str);
        },
        getDataTable(
            url = process.env.VUE_APP_BACKEND_URL_VERSION +
                "generate-report/penilaian-complete-potensi"
        ) {
            let self = this;
            self.loadingTable = true;
            self.tableData.idjadwal = self.idjadwal;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.table_data = response_data_fix.list_data.data;
                        self.configPagination(response_data_fix.list_data);
                        self.currentTablePage = response_data_fix.list_data.current_page;
                        self.loadingTable = false;

                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("DD-MM-YYYY HH:mm");
        },
        openDetailModal(data_detail) {
            this.showDetailPotensi = !this.showDetailPotensi;
            this.data_detail = data_detail;
        },
        onChangeAsesmenGrade() {
            this.selected_id_asesmen_grade =
                this.selected_asesmen_grade.id_jenis_asesmen;
            this.tableData.m_dirkom_idm_dirkom =
                this.selected_asesmen_grade.id_jenis_asesmen;
            this.tableData2.m_dirkom_idm_dirkom =
                this.selected_asesmen_grade.id_jenis_asesmen;
            this.getDataTable();
        },
        exportCompletePotensi() {
            let self = this;
            self.tableData.idjadwal = self.idjadwal;
            var config = {
                method: "get",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "generate-report/export-complete-potensi",
                responseType: "blob",
                crossdomain: true,
                params: this.tableData,
                headers: {
                    Accept: "application/vnd.ms-excel",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config).then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const filename =
                    "Export Potensi" +
                    new Date().toISOString().slice(0, 10) +
                    ".xlsx";
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
            });
        },

        exportUncompletePotensi() {
            let self = this;
            self.tableData.idjadwal = self.idjadwal;
            var config = {
                method: "get",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "generate-report/export-uncomplete-potensi",
                responseType: "blob",
                crossdomain: true,
                params: this.tableData,
                headers: {
                    Accept: "application/vnd.ms-excel",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config).then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const filename =
                    "Export_peserta_belum_potensi" +
                    new Date().toISOString().slice(0, 10) +
                    ".xlsx";
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
            });
        },
    },
};
</script>
<template>
    <div class="mt-4">
        <!-- <div class="row">
            <div class="col-4">
                <label for="Batch">Pilih Job Target</label>
                <v-select
                    :options="option_jobtarget"
                    v-model="selected_jobtarget"
                    label="m_jobtarget_nama"
                    placeholder="-Pilih Job Target-"
                    @update:modelValue="onChangeJobTarget()"
                ></v-select>
            </div>
        </div>
        <div
            class="row mt-4"
            v-if="selected_jobtarget == ''"
        >
            <b-alert
                variant="primary"
                show
            >Silahkan pilih salah satu Job Target</b-alert>
        </div> -->
        <div>
            <div class="row">
                <div class="row">
                    <div class="col-12 text-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="exportCompletePotensi()"
                        >
                            Export
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row"></div>
                        <hr />
                        <div class="row">
                            <div class="col-md-4">
                                <table>
                                    <tr>
                                        <td>Show</td>
                                        <td>
                                            <select
                                                class="form-control form-control-sm"
                                                id="showpaginatetable"
                                                style="width: 100%"
                                                v-model="tableData.length"
                                                @change="getDataTable()"
                                            >
                                                <option
                                                    value="10"
                                                    selected
                                                >10</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                            </select>
                                        </td>
                                        <td>Entries</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-md-5">&nbsp;</div>
                            <div class="col-md-3">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    id="searchTable"
                                    placeholder="Cari Data ..."
                                    v-model="tableData.search"
                                    @input="searchDatatable"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <table class="table table-bordered mb-0 mt-2">
                        <thead class="bg-dark text-white">
                            <th class="text-center p-1">No</th>
                            <th class="text-center p-1">NIP</th>
                            <th class="text-center p-1">Nama</th>
                            <th
                                class="text-center p-1"
                                style="width: 10%;"
                                v-for="(alat, index) in data_alattes"
                                :key="index"
                            >
                                {{ alat.m_alat_tes_nama }}
                            </th>
                        </thead>
                        <tbody>
                            <tr v-if="loadingTable">
                                <td colspan="6">
                                    <i class="fas fa-spinner fa-spin"></i> Loading...
                                </td>
                            </tr>
                            <tr v-else-if="table_data == ''">
                                <td
                                    class="text-center"
                                    colspan="6"
                                >Data Tidak Tersedia</td>
                            </tr>
                            <tr
                                v-else
                                v-for="(row_data, key_data) in table_data"
                                :key="key_data"
                            >
                                <td class="text-center">
                                    <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                                    <div v-else>
                                        {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                                        }}{{ key_data + 1 }}.
                                    </div>
                                </td>
                                <td>{{ row_data.ja_nip }}</td>
                                <td>{{ row_data.ja_nama }}</td>
                                <td
                                    class="text-center p-1"
                                    v-for="(alat, index) in data_alattes"
                                    :key="index"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="is-loader"
                                        style="pointer-events: none;"
                                        :checked="(filteredAlatTesData(JSON.parse(row_data.alat_tes_data), alat.m_alat_tes_id))"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <pagination
                        :pagination="pagination"
                        @to-page="toPage"
                    ></pagination>
                </div>
            </div>
        </div>
    </div>
    <b-modal
        v-model="showDetailPotensi"
        id="modal-lg"
        size="lg"
        :title="'Detail Nilai Potensi ' + data_detail.nama_asesi"
        title-class="font-18"
        hide-footer
    >
        <DetailPotensi
            v-if="showDetailPotensi"
            :data_detail="data_detail"
        />
    </b-modal>
</template>
