<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

import { debounce } from "@/helpers/debounce";
import Peserta from "./peserta/list.vue";
import KompetensiJobTarget from "./konfigurasi_kompetensi/list_konfigurasi_kompetensi.vue";
import PenilaianSoftCompetency from "./penilaian_kompetensi/list_penilaian_soft_comptency.vue";
import PenilaianPotensi from "./penilaian_potensi/list_penilaian_potensi.vue";
import { decryptData } from "@/helpers/crypt.js";
import { encryptData } from "@/helpers/crypt.js";
/**
 *  Detailac
 */
export default {
  page: {
    title: "Detail Asesmen Center",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Peserta,
    KompetensiJobTarget,
    PenilaianPotensi,
    PenilaianSoftCompetency,
  },
  watch: {
    $route() {
      this.idjadwal = decryptData(this.$route.params.id);
      this.getDataJadwal();
      this.currentActiveIndex = 0;
    },
  },
  data() {
    return {
      data_jadwal: [],
      idjadwal: decryptData(this.$route.params.id),
      data_petugas: [],
      data_alat_test: [],
      perhitungan_qc_ptl: [],
      hasil_qc: [],
      showModalUpdateStatus: false,
      showModalUpdateGrup: false,
      showQcPtl: false,
      currentActiveIndex: 0,
      option_status: [
        {
          label: "Draft",
          status: "Draft",
        },
        {
          label: "Proses Verifikasi PTL",
          status: "proses_ptl",
        },
        {
          label: "Selesai",
          status: "selesai",
        },
      ],
      selected_status: "",
      selected_grup: "",
      cnt: 0,
      list_simulasi: null,
      tab_index: 3,
      show_potensi: false,
      show_kompetensi: false
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.$route.params.id);
    this.getDataJadwal();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    updateCnt() {
      return this.cnt++;
    },
    showHideQcPTL(status) {
      let self = this;
      self.showQcPtl = status;
      if (status == true) {
        self.getPerhitunganQcPTL();
        self.getHasilQc();
      }
    },

    getPerhitunganQcPTL() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/generate-report/perthitungan-qc-ptl",
        params: {
          idjadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.perhitungan_qc_ptl = response_data.data[0].list_data;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getHasilQc() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/generate-report/hasil-qc-ptl",
        params: {
          idjadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.hasil_qc = response_data.data[0].list_data;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //search & get jenis asesmen
    getDataJadwal() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center",
        params: {
          idjadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.data_jadwal = response_data.data.list_data;
            if (self.data_jadwal.j_tipe == 'Kompetensi & Potensi') {
              self.tab_index = 3;
              self.show_kompetensi = true;
              self.show_potensi = true;
            } else if (self.data_jadwal.j_tipe == 'Kompetensi') {
              self.tab_index = 2;
              self.show_kompetensi = true;
            } else if (self.data_jadwal.j_tipe == 'Potensi') {
              self.tab_index = 2;
              self.show_potensi = true;
            }
            self.data_petugas = JSON.parse(self.data_jadwal.j_json_petugas);
            self.data_alat_test = JSON.parse(self.data_jadwal.j_json_alattes);
            self.list_simulasi = response_data.data.list_data?.materi?.simulasi;
          }
          if (self.list_simulasi) {
            self.list_simulasi.forEach((item) => {
              if (item.simulasi_nonaktif.length > 0) {
                item.checked = false;
              } else {
                item.checked = true;
              }
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    updateStatus(status) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin mengubah status jadwal?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/update-status",
            data: {
              id: self.data_jadwal.idjadwal,
              status,
              tipe: "Assesment",
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.meta.code === 200) {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Berhasil Mengubah Status ",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    Swal.close();
                    window.location.reload();
                  }
                });
              } else {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  text: "Gagal Mengubah Status ",
                  showConfirmButton: true,
                });
              }
            })
            .catch((response) => {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Gagal",
                text: "Terjadi kesalahan: " + response.response.data.data[0],
                showConfirmButton: true,
              });
            });
        } else {
          Swal.close();
        }
      });
    },

    updateGrup(nama) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin mengubah nama grup?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/generate-report/update-grup",
            data: {
              idgrup: self.data_jadwal.jadwal_grup_idjadwal_grup,
              nama_grup: nama,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            if (response.data.meta.code == 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Mengubah Nama Grup ",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },

    deleteJadwal() {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus jadwal?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/generate-report/delete-jadwal",
            data: {
              id_jadwal: self.data_jadwal.idjadwal,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            if (response.data.meta.code == 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Menghapus Jadwal ",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.$router.push({ name: "all-generate-report" });
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    totalPesertaDiundang(data) {
      if (!data) {
        return "0";
      }
      var parse_data = JSON.parse(data);
      return parse_data.reduce((total, unit) => total + parseInt(unit.ju_peserta_diundang), 0);
    },
    countSkorAsesor(data) {
      let total = 0;
      let skor = 0;
      let sum = 0;
      data.map(function (x) {
        skor += parseFloat(x.skor);
        sum += 1;
      });
      total = skor / sum;
      return total;
    },
    redirectJadwal(idjadwal) {
      const route = this.$router.resolve({
        name: "detail-generate-report",
        params: {
          id: this.encryptedUrl(idjadwal.toString()),
        },
      });
      window.open(route.href, "_blank");
    },
    hasilSurvey() {
      var encrypted_id = this.data_jadwal.encrypted_id;
      var current_url = window.location.href;
      current_url = encodeURIComponent(current_url);

      const externalLinkUrl = `${process.env.VUE_APP_UPAC_CI_URL}pelayanan/penjadwalan/detailpenjadwalan_hasilsurvey/${encrypted_id}?current_url=${current_url}`;
      window.open(externalLinkUrl, "_blank");
      this.currentActiveIndex = 1;
    },
    rolePlayer() {
      var encrypted_id = this.data_jadwal.encrypted_id;
      var current_url = window.location.href;
      current_url = encodeURIComponent(current_url);

      const externalLinkUrl = `${process.env.VUE_APP_UPAC_CI_URL}pelayanan/penjadwalan/detailpenjadwalanroleplayer/${encrypted_id}?current_url=${current_url}`;
      window.open(externalLinkUrl, "_blank");
      this.currentActiveIndex = 1;
    },
    simulasiChange(simulasi) {
      if (!simulasi.checked) {
        this.sendSimulasi("uncheck");
      } else {
        this.sendSimulasi("check");
      }
    },
    sendSimulasi(tipe) {
      let data = null;
      if (tipe == "uncheck") {
        const checkedItems = this.list_simulasi.filter((list_simulasi) => !list_simulasi.checked);
        data = checkedItems.map((list_simulasi) => ({
          id_simulasi: list_simulasi.id_simulasi,
          id_simulasi_config: list_simulasi.id_simulasi_config,
          id_materi: list_simulasi.id_materi,
          id_jenisasesmen: list_simulasi.ns_jenisasesmen,
          level: list_simulasi.level,
          id_jadwal: this.idjadwal,
          tipe: "uncheck",
        }));
      } else {
        const checkedItems = this.list_simulasi.filter((list_simulasi) => list_simulasi.checked);
        data = checkedItems.map((list_simulasi) => ({
          id_simulasi: list_simulasi.id_simulasi,
          id_simulasi_config: list_simulasi.id_simulasi_config,
          id_materi: list_simulasi.id_materi,
          id_jenisasesmen: list_simulasi.ns_jenisasesmen,
          level: list_simulasi.level,
          id_jadwal: this.idjadwal,
          tipe: "check",
        }));
      }

      if (data == null) {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Gagal update simulasi, Data kosong",
          showConfirmButton: true,
        });
        return false;
      }

      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/update-simulasi",
        data: data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        showConfirmButton: false,
      });
      axios(config)
        .then((response) => {
          if (response.data.meta.code === 200) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil update simulasi",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
              }
            });
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Gagal",
              text: "Gagal update simulasi ",
              showConfirmButton: true,
            });
          }
        })
        .catch((response) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: "Terjadi kesalahan: " + response.response.data.data[0],
            showConfirmButton: true,
          });
        });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Detail Jadwal WO Generate Report</div>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <td
                          scope="row"
                          style="width: 20%"
                        >ID Jadwal</td>
                        <td style="width: 5%">:</td>
                        <td>
                          <div class="d-flex justify-content-between">
                            #{{ data_jadwal?.idjadwal }}
                            <div v-if="data_jadwal?.j_status == 'Draft'">
                              <span class="badge badge-pill badge-soft-info font-size-12">Draft</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'void'">
                              <span class="badge badge-pill badge-soft-danger font-size-12">Batal</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'batal'">
                              <span class="badge badge-pill badge-soft-danger font-size-12">Batal</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'proses_ptl'">
                              <span class="badge badge-pill badge-soft-info font-size-12">Proses Verifikasi PTL</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'verifikasi'">
                              <span class="badge badge-pill badge-soft-info font-size-12">Verifikasi Asesi</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'MK Draft'">
                              <span class="badge badge-pill badge-soft-info font-size-12">MK Draft</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'MK Fix'">
                              <span class="badge badge-pill badge-soft-info font-size-12">MK Fix</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'selesai'">
                              <span class="badge badge-pill badge-soft-success font-size-12">Selesai</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Tipe Jadwal</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_tipe }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Metode Jadwal Sebelumnya</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_metode }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Jenis Asesment</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_txt_dirkom }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Job Target</td>
                        <td>:</td>
                        <td>
                          <div v-if="data_jadwal?.jadwal_job_target && data_jadwal.jadwal_job_target.length > 0">
                            <ul>
                              <li
                                v-for="job_target in data_jadwal?.jadwal_job_target"
                                :key="job_target.idjadwal_jobtarget"
                              >
                                <p>{{ job_target.m_jobtarget_nama }}</p>
                              </li>
                            </ul>
                          </div>
                          <div v-else>-</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <td
                          scope="row"
                          style="width: 20%"
                        >PTL</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_txt_ptl }}</td>
                      </tr>
                      <tr
                        v-for="(jadwal, index) in data_petugas"
                        :key="index"
                      >
                        <td scope="row">
                          <span v-if="index == 0">Petugas</span>
                        </td>
                        <td><span v-if="index == 0">:</span></td>
                        <td>{{ index + 1 + ". " + jadwal.jp_txt_petugas }}</td>
                      </tr>
                      <tr
                        v-for="(alat_test, index) in data_alat_test"
                        :key="index"
                      >
                        <td scope="row">
                          <span v-if="index == 0">Alat Test</span>
                        </td>
                        <td><span v-if="index == 0">:</span></td>
                        <td>
                          {{ index + 1 + ". " + alat_test.m_alat_tes_nama }}
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Keterangan</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_keterangan }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="col-md-12 mt-4"
                v-if="data_jadwal?.jadwal_grup != null"
              >
                <template
                  v-for="(jadwal, index) in data_jadwal.jadwal_grup.jadwal_new"
                  :key="index"
                >
                  <a
                    v-if="jadwal.idjadwal != data_jadwal.idjadwal && jadwal.status != 'DISABLE'"
                    class="btn btn-info btn-sm me-2 mt-2"
                    @click="redirectJadwal(jadwal.idjadwal)"
                  >{{ jadwal.j_txt_kegiatan }} - {{ jadwal.j_txt_dirkom }} #{{ jadwal.idjadwal }}
                  </a>
                  <a
                    v-else
                    class="btn btn-info btn-sm me-2 mt-2 disabled"
                  > {{ jadwal.j_txt_kegiatan }} - {{ jadwal.j_txt_dirkom }} #{{ jadwal.idjadwal }} </a>
                </template>
              </div>
              <div class="col-md-12 mb-2 mt-4">
                <div class="d-inline-block">
                  <router-link
                    v-if="data_jadwal?.j_status == 'Draft'"
                    class="btn btn-info btn-sm me-2"
                    :to="{
                      name: 'edit-generate-report',
                      params: {
                        id: this.encryptedUrl(idjadwal.toString()),
                      },
                    }"
                  ><i class="fa fa-edit"></i> Edit
                  </router-link>
                  <a
                    v-if="data_jadwal?.j_status == 'Draft'"
                    class="btn btn-sm btn-danger me-2"
                    @click="deleteJadwal()"
                  ><i class="fas fa-times"></i> Batal</a>
                  <!-- <a
                    class="btn btn-sm btn-info me-2"
                    @click="showHideQcPTL(true)"
                  ><i class="fas fa-calendar-alt"></i> Penilaian QC
                    PTL</a> -->

                  <button
                    class="btn btn-success btn-sm"
                    @click="() => {
                      showModalUpdateStatus = true;
                    }
                    "
                  >
                    <i class="fas fa-sync-alt"></i>
                    Update Status Jadwal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div
            v-if="showQcPtl == false"
            class="card-body"
          >
            <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted text-secondary"
              v-model="currentActiveIndex"
            >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Peserta</span>
                </template>
                <Peserta
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 0"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Kompetensi Job Target</span>
                </template>
                <KompetensiJobTarget
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 1"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>
              <b-tab v-if="show_kompetensi">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Penilaian Soft Competency</span>
                </template>
                <PenilaianSoftCompetency
                  v-if="data_jadwal.length != 0 && currentActiveIndex == (show_kompetensi && show_potensi) ? (tab_index - 1) : tab_index"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>
              <b-tab v-if="show_potensi">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Penilaian Potensi</span>
                </template>
                <PenilaianPotensi
                  v-if="data_jadwal.length != 0 && currentActiveIndex == tab_index"
                  :data_jadwal="data_jadwal"
                  :data_alattes="data_alat_test"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showModalUpdateStatus"
      id="modal-lg"
      size="lg"
      title="Update Status"
      title-class="font-18"
      hide-footer
    >
      <div class="row">
        <div class="col-md-12">
          <div class="mb-3">
            <label
              for="formImport"
              class="form-label"
            >Update Status Jadwal</label>
            <v-select
              :options="option_status"
              v-model="selected_status"
              label="label"
              placeholder="-Pilih Status-"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">&nbsp;</div>
        <div class="col-md-6">
          <div class="text-end">
            &nbsp;
            <button
              type="button"
              class="btn btn-primary"
              @click="updateStatus(selected_status.status)"
            ><i class="fa fa-save"></i> Simpan</button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
