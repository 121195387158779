<script>
// import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import DetaiLSoftCompetency from "./detail_penilaian.vue";
import { decryptData } from "@/helpers/crypt.js";
export default {
    components: {
        // Datatable,
        Pagination,
        DetaiLSoftCompetency,
    },
    props: {
        data_jadwal: {
            type: Object,
        },
    },
    data() {
        let sortOrders = [];
        let sortOrders2 = []; // select sortOrder
        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "id_jadwalasesi" },
            { width: "auto", label: "NIP", name: "ja_nip" },
            { width: "auto", label: "Nama", name: "ja_nama" },
            { width: "auto", label: "ID Jadwal", name: "ja_idjadwal" },
            { width: "auto", label: "Jenis Asesmen", name: "j_txt_dirkom" },
            { width: "auto", label: "Tanggal Asesmen", name: "j_tanggal_mulai" },
            { width: "175px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        let columns2 = [
            { width: "40px", label: "-", name: "-" },
            { width: "50px", label: "No", name: "id_jadwalasesi" },
            { width: "auto", label: "NIP", name: "ja_nip" },
            { width: "auto", label: "Nama", name: "ja_nama" },
            { width: "auto", label: "Unit", name: "ja_unit" },
            { width: "auto", label: "Jabatan", name: "ja_jabatan" },
        ];
        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        columns2.forEach((column2) => {
            sortOrders2[column2.name] = -1;
        });
        return {
            option_asesmen_grade: this.data_jadwal.asesmen_grade,
            jenis_asesmen_id: this.data_jadwal.m_dirkom_idm_dirkom,
            selected_id_asesmen_grade: "",
            selected_asesmen_grade: [],

            idjadwal: "",
            selected_idasesi: "",
            selected_nama: "",
            table_data: [],
            loadingTable: true,
            columns: columns,
            sortKey: "id_role", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",
            selected_peserta: [],
            showDetailSoftCompetency: false,
            disabledCheckboxPeserta: false,
            selectedParticipants: [],
            disabledCheckboxes: [],
            is_process: false,
            selected_nip: "",
            sessionRoleId: localStorage.session_role_active_id,
            jenis_asesmen: "",
            id_jenisasesmen: "",
            tanggal_mulai_pelaksanaan: "",
            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                idjadwal: "",
                role_id: localStorage.session_role_active_id,
                username: localStorage.session_username,
                m_dirkom_idm_dirkom: this.data_jadwal.m_dirkom_idm_dirkom,
            },
            syncData: {
                idjadwal: "",
                lastSync: "",
            },
            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },

            table_data2: [],
            loadingTable2: true,
            columns2: columns2,
            sortKey2: "id_role", // sort key awal
            sortOrders2: sortOrders2,
            currentTablePage2: "",
            // parsing data ke table data
            tableData2: {
                draw: 0,
                length: 50,
                search: "",
                column: 0,
                dir: "asc",
                idjadwal: "",
                completed_idasesi: [],
                role_id: localStorage.session_role_active_id,
                username: localStorage.session_username,
                m_dirkom_idm_dirkom: this.data_jadwal.m_dirkom_idm_dirkom,
            },
            // config untuk paginasi
            pagination2: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },

            selected_jobtarget: "",
            option_jobtarget: [],

            data_asesi: [],
            data_jadwal_kompetensi: [],

            checkedAll: false,
            selectedData: []
        };
    },
    mounted() {
        this.idjadwal = decryptData(this.$route.params.id);
        // this.syncCompleteSoftCompetency();
        // this.getLastSync();
        // this.searchJobTarget();
        this.getDataTable();
    },
    created() {
        this.searchDatatable = debounce(() => {
            this.getDataTable();
            // call fetch API to get results
        }, 500);
        this.searchDatatable2 = debounce(() => {
            this.getDataSelfCompentency();
            // call fetch API to get results
        }, 500);
    },
    methods: {
        checkPeserta() {
            let self = this;
            if (this.checkedAll) {
                self.table_data.forEach((option) => {
                    self.selectedData.push(option);
                });
            } else {
                self.checkedAll = false;
                self.selectedData = [];
            }
        },
        searchJobTarget: function (search) {
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/job-target-jadwal",
                params: {
                    keyword: search,
                    id_jadwal: self.idjadwal
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config).then((response) => {
                if (response.data.meta.code == 200) {
                    self.option_jobtarget = response.data.data.referensi;
                }
            });
        },
        configPagination(data) {
            let self = this;
            self.pagination.lastPage = data.last_page;
            self.pagination.currentPage = data.current_page;
            self.pagination.total = data.total;
            self.pagination.lastPageUrl = data.last_page_url;
            self.pagination.nextPageUrl = data.next_page_url;
            self.pagination.prevPageUrl = data.prev_page_url;
            self.pagination.from = data.from;
            self.pagination.to = data.to;
            self.pagination.links = data.links;
        },
        sortBy(key) {
            let self = this;
            self.sortKey = key;
            self.sortOrders[key] = self.sortOrders[key] * -1;
            self.tableData.column = self.getIndex(self.columns, "name", key);
            self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
            self.getDataTable();
        },
        getIndex(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },
        toPage: function (str) {
            let self = this;
            self.getDataTable(str);
        },
        getDataTable(
            url = process.env.VUE_APP_BACKEND_URL_VERSION +
                "generate-report/penilaian-complete-soft-competency"
        ) {
            let self = this;
            self.table_data = [];
            self.loadingTable = true;
            self.tableData.idjadwal = self.idjadwal;
            self.tableData.jenis_asesmen_id = self.jenis_asesmen_id;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.table_data = response_data_fix.list_data.data;
                        self.data_jadwal_kompetensi = response_data_fix.data_kompetensi;
                        self.configPagination(response_data_fix.list_data);
                        self.currentTablePage = response_data_fix.list_data.current_page;
                        self.loadingTable = false;

                        self.table_data.forEach((element) => {
                            self.tableData2.completed_idasesi.push(element.ja_idasesi);
                        });
                        this.getDataSelfCompentency();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },

        fullDateTimeFormat(datetime) {
            return moment(datetime).format("DD-MM-YYYY HH:mm");
        },

        configPagination2(data) {
            let self = this;
            self.pagination2.lastPage = data.last_page;
            self.pagination2.currentPage = data.current_page;
            self.pagination2.total = data.total;
            self.pagination2.lastPageUrl = data.last_page_url;
            self.pagination2.nextPageUrl = data.next_page_url;
            self.pagination2.prevPageUrl = data.prev_page_url;
            self.pagination2.from = data.from;
            self.pagination2.to = data.to;
            self.pagination2.links = data.links;
        },
        sortBy2(key) {
            let self = this;
            self.sortKey2 = key;
            self.sortOrders2[key] = self.sortOrders2[key] * -1;
            self.tableData2.column = self.getIndex2(self.columns2, "name", key);
            self.tableData2.dir = self.sortOrders2[key] === 1 ? "asc" : "desc";
            self.getDataSelfCompentency();
        },
        getIndex2(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },
        toPage2: function (str) {
            let self = this;
            self.getDataSelfCompentency(str);
        },
        getDataSelfCompentency(
            url = process.env.VUE_APP_BACKEND_URL_VERSION +
                "generate-report/penilaian-uncomplete-soft-competency"
        ) {
            let self = this;
            self.loadingTable2 = true;
            self.tableData2.idjadwal = self.idjadwal;

            var config = {
                method: "get",
                url: url,
                params: self.tableData2,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.table_data2 = response_data_fix.list_data.data;
                        self.configPagination2(response_data_fix.list_data);
                        self.currentTablePage2 = response_data_fix.list_data.current_page;
                        self.loadingTable2 = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        openDetailModal(data_asesi) {
            this.showDetailSoftCompetency = !this.showDetailSoftCompetency;
            this.data_asesi = data_asesi;
        },
        checkAllPeserta() {
            let self = this;
            if (this.checkedAll) {
                self.table_data2.forEach((option, index) => {
                    if (index <= 15) {
                        self.selected_peserta.push(option);
                    } else {
                        return false;
                    }
                });
            } else {
                self.selected_peserta = [];
            }
        },
        syncCompleteSoftCompetency(
            url = process.env.VUE_APP_BACKEND_URL_VERSION +
                "master/jadwal-pegpog/sync-complete-soft-competency"
        ) {
            let self = this;
            self.loadingTable2 = true;
            self.syncData.idjadwal = self.idjadwal;
            self.is_process = true;
            var config = {
                method: "get",
                url: url,
                params: self.syncData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    if (response.data.meta.code == 200) {
                        this.getLastSync();
                        // Swal.fire({
                        //   icon: "success",
                        //   title: "Berhasil!",
                        //   text: response.data.meta.message,
                        // });
                        self.is_process = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response.data.meta.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        getLastSync(
            url = process.env.VUE_APP_BACKEND_URL_VERSION +
                "master/jadwal-pegpog/last-sync-complete-kompetensi"
        ) {
            let self = this;
            self.loadingTable2 = true;
            self.syncData.idjadwal = self.idjadwal;

            var config = {
                method: "get",
                url: url,
                params: self.syncData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    this.syncData.lastSync = response.data.data;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        isCheckboxDisabled(peserta) {
            // Disable the checkbox if more than two participants are selected,
            // except for the participant with the given ID.
            return (
                this.selected_peserta.length == 18 &&
                !this.selected_peserta.includes(peserta)
            );
        },

        onChangeJobTarget() {
            this.tableData.id_job_target = this.selected_jobtarget.m_jobtarget_id;
            this.getDataTable();
        },

        generateReportKompetensi() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let tipe = null;
            if (self.data_jadwal.j_metode == 'Psikologi') {
                tipe = 'AP';
            } else if (self.data_jadwal.j_metode == 'Assessment') {
                tipe = 'AC';
            }
            var config = {
                method: "post",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "generate-report/generate-report-kompetensi",
                data: {
                    data_jadwal_kompetensi: self.data_jadwal_kompetensi,
                    id_jadwal: self.idjadwal,
                    tipe_jadwal: tipe,
                }
                ,
                headers: {
                    Accept: "application/vnd.ms-excel",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    if (response.data.meta.code == 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Berhasil Generate Report",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.getDataTable();
                                Swal.close();
                            }
                        });
                    }
                })
                .catch(() => {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Terjadi kesalahan",
                    });
                });
        },

        exportUncompleteSoftCompetency() {
            let self = this;
            self.tableData.idjadwal = self.idjadwal;
            var config = {
                method: "get",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "generate-report/export-uncomplete-soft-competency",
                responseType: "blob",
                crossdomain: true,
                params: this.tableData,
                headers: {
                    Accept: "application/vnd.ms-excel",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config).then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const filename =
                    "Export_peserta_belum_soft_competency" +
                    new Date().toISOString().slice(0, 10) +
                    ".xlsx";
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
            });
        },

        getKompetensi(selectedData) {
            let self = this;
            if (self.selectedData.length == 0) {
                Swal.fire({
                    icon: "warning",
                    title: "Opps",
                    text: "Tidak ada data yang dipilih!!!",
                });
                return false
            }
            Swal.fire({
                title: "Perhatian",
                text: "Anda yakin ingin?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Iya`,
                denyButtonText: `Tidak`,
            }).then((result) => {
                if (result.isConfirmed) {
                    self.loadingTable = true;
                    Swal.fire({
                        title: '<i class="fas fa-spinner fa-spin"></i>',
                        text: "Loading...",
                        showConfirmButton: false,
                    });
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "generate-report/store-kompetensi",
                        data: {
                            data_jadwal_kompetensi: selectedData,
                            id_jadwal: self.idjadwal,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                    axios(config).then((response) => {
                        if (response.data.meta.code == 200) {
                            Swal.close();
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Berhasil Get Kompetensi Data Peserta ",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    Swal.close();
                                    self.loadingTable = false;
                                    self.getDataTable();
                                    // window.location.reload();
                                }
                            });
                        }
                    }).catch((error) => {
                        self.loadingTable = false;
                        self.getDataTable();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: error.response.data.data.message,
                        });
                    });
                } else {
                    Swal.close();
                }
            });
        },
    },
};
</script>
<template>
    <div class="mt-4">
        <!-- <div class="row">
            <div class="col-4">
                <label for="Batch">Pilih Job Target</label>
                <v-select
                    :options="option_jobtarget"
                    v-model="selected_jobtarget"
                    label="m_jobtarget_nama"
                    placeholder="-Pilih Job Target-"
                    @update:modelValue="onChangeJobTarget()"
                ></v-select>
            </div>
        </div>
        <div
            class="row mt-4"
            v-if="selected_jobtarget == ''"
        >
            <b-alert
                variant="primary"
                show
            >Silahkan pilih salah satu Job Target</b-alert>
        </div> -->
        <div>
            <div class="row mt-4">
                <div class="col-6">
                    <h4>Peserta Yang Punya Nilai Kompetensi</h4>
                </div>

                <div class="col-md-6 text-end">
                    <button
                        v-if="data_jadwal.j_status == 'Draft' && data_jadwal.j_metode == 'Assessment'"
                        class="btn btn-sm btn-primary"
                        @click="getKompetensi(selectedData)"
                    ><i class="fa fa-calendar"></i> Get Kompetensi</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-2">
                    <div class="row mb-3"></div>
                    <hr />
                    <div class="row">
                        <div class="col-md-4">
                            <table>
                                <tr>
                                    <td>Show</td>
                                    <td>
                                        <select
                                            class="form-control form-control-sm"
                                            id="showpaginatetable"
                                            style="width: 100%"
                                            v-model="tableData.length"
                                            @change="getDataTable()"
                                        >
                                            <option
                                                value="10"
                                                selected
                                            >10</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                        </select>
                                    </td>
                                    <td>Entries</td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-5">&nbsp;</div>
                        <div class="col-md-3">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="searchTable"
                                placeholder="Cari Data ..."
                                v-model="tableData.search"
                                @input="searchDatatable"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <table class="table table-nowrap table-bordered mb-0">
                    <thead class="bg-dark text-center text-white">
                        <tr>
                            <th>No</th>
                            <th>Nip</th>
                            <th>Nama</th>
                            <th>ID Jadwal</th>
                            <th>Jenis Asesmen</th>
                            <th>Tanggal Asesmen</th>
                            <th>Aksi</th>
                            <th v-if="data_jadwal.j_status == 'Draft' && data_jadwal.j_metode == 'Assessment'">
                                <input
                                    type="checkbox"
                                    v-model="checkedAll"
                                    @change="checkPeserta($event)"
                                />
                            </th>
                            <th v-if="data_jadwal.j_status == 'Draft' && data_jadwal.j_metode == 'Assessment'">Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="loadingTable">
                            <td colspan="6">
                                <i class="fas fa-spinner fa-spin"></i> Loading...
                            </td>
                        </tr>
                        <tr v-else-if="table_data == ''">
                            <td
                                class="text-center"
                                colspan="6"
                            >Data Tidak Tersedia</td>
                        </tr>
                        <tr
                            v-else
                            v-for="(row_data, key_data) in table_data"
                            :key="key_data"
                        >
                            <td class="text-center">
                                <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                                <div v-else>
                                    {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                                    }}{{ key_data + 1 }}.
                                </div>
                            </td>
                            <td>{{ row_data.nip }}</td>
                            <td>{{ row_data.nama_asesi }}</td>
                            <td>#{{ row_data.idjadwal }}</td>
                            <td>{{ row_data.jenis_asesmen }}</td>
                            <td>{{ row_data.tanggal_mulai_pelaksanaan }}</td>
                            <td class="text-center">
                                <button
                                    type="button"
                                    class="btn btn-info btn-sm"
                                    @click="
                                        openDetailModal(row_data)
                                        "
                                >
                                    <i class="fa fa-eye"></i> Detail
                                </button>
                            </td>
                            <td
                                v-if="data_jadwal.j_status == 'Draft' && data_jadwal.j_metode == 'Assessment'"
                                class="text-center"
                            >
                                <div>
                                    <input
                                        type="checkbox"
                                        :id="row_data"
                                        v-model="selectedData"
                                        :value="row_data"
                                        size="lg"
                                    />
                                </div>
                            </td>
                            <td v-if="data_jadwal.j_status == 'Draft' && data_jadwal.j_metode == 'Assessment'">
                                <span
                                    style="white-space: nowrap"
                                    class="badge bg-success"
                                    v-if="row_data.status == 'Done'"
                                >
                                    Done
                                </span>
                                <span
                                    style="white-space: nowrap"
                                    class="badge bg-danger"
                                    v-if="row_data.status == 'Not Yet'"
                                >
                                    Not Yet
                                </span>
                                <span
                                    style="white-space: nowrap"
                                    class="badge bg-warning"
                                    v-else-if="row_data.status == 'On Process'"
                                >
                                    On Process
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <pagination
                    :pagination="pagination"
                    @to-page="toPage"
                ></pagination>
            </div>
            <div class="row">
                <h4>Peserta Yang Belum Punya Nilai Kompetensi</h4>
            </div>

            <div class="row">
                <div class="col-md-12 mb-2">

                    <div class="row mb-3"></div>
                    <hr />
                    <div class="row">
                        <div class="col-12 text-end">
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="exportUncompleteSoftCompetency()"
                            >
                                Export
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!-- <button
              v-if="selected_peserta.length > 0"
              class="btn btn-info btn-sm me-2"
              @click="checkAllPeserta()"
            >
              Pilih Peserta
            </button> -->
                            <router-link
                                class="btn btn-success btn-sm"
                                v-if="selected_peserta.length >= 1"
                                :to="{
                                    name: 'add-jadwal-asesmen',
                                    params: {
                                        id: idjadwal,
                                        // data_peserta : selected_peserta
                                        // dataPeserta: JSON.stringify(selected_peserta),
                                    },
                                    state: {
                                        data_peserta: JSON.stringify(selected_peserta),
                                        data_jadwal: JSON.stringify(data_jadwal),
                                    },
                                }"
                            ><i class="fa fa-edit"></i> Buat Jadwal Asesmen
                            </router-link>

                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <table>
                                <tr>
                                    <td>Show</td>
                                    <td>
                                        <select
                                            class="form-control form-control-sm"
                                            id="showpaginatetable"
                                            style="width: 100%"
                                            v-model="tableData2.length"
                                            @change="getDataSelfCompentency()"
                                        >
                                            <option
                                                value="10"
                                                selected
                                            >10</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                        </select>
                                    </td>
                                    <td>Entries</td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-5">&nbsp;</div>
                        <div class="col-md-3">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="searchTable"
                                placeholder="Cari Data ..."
                                v-model="tableData2.search"
                                @input="searchDatatable2"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <table class="table mb-0 table-bordered table-condensed table-hover">
                    <thead class="bg-dark text-center text-white">
                        <tr>
                            <!-- <th>
                                <input
                                    type="checkbox"
                                    v-model="checkedAll"
                                    @change="checkAllPeserta()"
                                />
                            </th> -->
                            <th>No</th>
                            <th>Nip</th>
                            <th>Nama</th>
                            <th>Unit</th>
                            <th>Jabatan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="loadingTable2">
                            <td colspan="6">
                                <i class="fas fa-spinner fa-spin"></i> Loading...
                            </td>
                        </tr>
                        <tr v-else-if="table_data2 == ''">
                            <td
                                class="text-center"
                                colspan="6"
                            >Data Tidak Tersedia</td>
                        </tr>
                        <tr
                            v-else
                            v-for="(row_data, key_data) in table_data2"
                            :key="key_data"
                        >
                            <!-- <td>
                                <div v-if="row_data?.status_jadwal_ac == false">
                                    <input
                                        type="checkbox"
                                        :id="row_data.id_jadwalasesi"
                                        v-model="selected_peserta"
                                        :value="row_data"
                                        :disabled="isCheckboxDisabled(row_data)"
                                    />
                                </div>
                            </td> -->
                            <td class="text-center">
                                <div v-if="key_data + 1 == 10">{{ currentTablePage2 }}0.</div>
                                <div v-else>
                                    {{ currentTablePage2 - 1 != 0 ? currentTablePage2 - 1 : ""
                                    }}{{ key_data + 1 }}.
                                </div>
                            </td>
                            <td>{{ row_data.ja_nip }}</td>
                            <td>{{ row_data.ja_nama }}</td>
                            <td>{{ row_data.ja_unit }}</td>
                            <td>{{ row_data.ja_jabatan }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <pagination
                    :pagination="pagination2"
                    @to-page="toPage2"
                ></pagination>
            </div>
        </div>
    </div>
    <b-modal
        v-model="showDetailSoftCompetency"
        id="modal-lg"
        size="xl"
        :title="'Detail Nilai Soft Competency - ' + data_asesi.nama_asesi"
        title-class="font-18"
        hide-footer
    >
        <DetaiLSoftCompetency
            v-if="showDetailSoftCompetency"
            :data_asesi="data_asesi"
        />
    </b-modal>
</template>
