<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { decryptData } from "@/helpers/crypt.js";
export default {
    components: {
        Datatable,
        Pagination,
    },
    props: {
        data_jadwal: {
            type: Object,
        },
    },
    data() {
        let sortOrders = []; // select sortOrder
        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "id_jt" },
            {
                width: "auto",
                label: "Dirkom",
                name: "nama_cluster",
            },
            {
                width: "auto",
                label: "Cluster",
                name: "nama_cluster",
            },
            {
                width: "auto",
                label: "Kompetensi",
                name: "nama_cluster",
            },
            {
                width: "auto",
                label: "Inisial",
                name: "nama_cluster",
            },
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        return {
            option_asesmen_grade: this.data_jadwal.asesmen_grade,
            selected_id_job_target: "",

            idjadwal: "",
            table_data: [],
            loadingTable: true,
            columns: columns,
            sortKey: "id_role", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",
            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
            },
            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
            path_file_import: "",


            selected_jobtarget: "",
            option_jobtarget: [],
        };
    },
    mounted() {
        this.idjadwal = decryptData(this.$route.params.id);
        this.searchJobTarget();
    },
    created() {
        this.searchDatatable = debounce(() => {
            this.getDataTable();
            // call fetch API to get results
        }, 500);
    },
    methods: {
        configPagination(data) {
            let self = this;
            self.pagination.lastPage = data.last_page;
            self.pagination.currentPage = data.current_page;
            self.pagination.total = data.total;
            self.pagination.lastPageUrl = data.last_page_url;
            self.pagination.nextPageUrl = data.next_page_url;
            self.pagination.prevPageUrl = data.prev_page_url;
            self.pagination.from = data.from;
            self.pagination.to = data.to;
            self.pagination.links = data.links;
        },
        sortBy(key) {
            let self = this;
            self.sortKey = key;
            self.sortOrders[key] = self.sortOrders[key] * -1;
            self.tableData.column = self.getIndex(self.columns, "name", key);
            self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
            self.getDataTable();
        },
        searchJobTarget: function (search) {
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/job-target-jadwal",
                params: {
                    keyword: search,
                    id_jadwal: self.idjadwal
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config).then((response) => {
                if (response.data.meta.code == 200) {
                    self.option_jobtarget = response.data.data.referensi;
                }
            });
        },
        getIndex(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },
        toPage: function (str) {
            let self = this;
            self.getDataTable(str);
        },
        getDataTable(
            url = process.env.VUE_APP_BACKEND_URL_VERSION +
                "generate-report/get-kompetensi"
        ) {
            let self = this;
            self.loadingTable = true;
            self.tableData.id_jadwal = self.idjadwal;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.table_data = response_data_fix.list_data.data;
                        self.configPagination(response_data_fix.list_data);
                        self.currentTablePage = response_data_fix.list_data.current_page;
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },

        configPagination2(data) {
            let self = this;
            self.pagination2.lastPage = data.last_page;
            self.pagination2.currentPage = data.current_page;
            self.pagination2.total = data.total;
            self.pagination2.lastPageUrl = data.last_page_url;
            self.pagination2.nextPageUrl = data.next_page_url;
            self.pagination2.prevPageUrl = data.prev_page_url;
            self.pagination2.from = data.from;
            self.pagination2.to = data.to;
            self.pagination2.links = data.links;
        },

        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD HH:mm");
        },
        onChangeJobTarget() {
            this.tableData.id_job_target = this.selected_jobtarget.m_jobtarget_id;
            this.getDataTable();
        },

        //search & get jenis asesme
    },
};
</script>
<template>
    <div class="mt-4">
        <div class="row">
            <div class="col-4">
                <label for="Batch">Pilih Job Target</label>
                <v-select
                    :options="option_jobtarget"
                    v-model="selected_jobtarget"
                    label="m_jobtarget_nama"
                    placeholder="-Pilih Job Target-"
                    @update:modelValue="onChangeJobTarget()"
                ></v-select>
            </div>
        </div>
        <div
            class="row mt-4"
            v-if="selected_jobtarget == ''"
        >
            <b-alert
                variant="primary"
                show
            >Silahkan pilih salah satu Job Target</b-alert>
        </div>
        <div v-else>
            <div class="row mt-4">
                <div class="row">
                    <h4>Kompetensi</h4>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <div class="row mb-3"></div>
                        <hr />
                        <div class="row">
                            <div class="col-md-4">
                                <table>
                                    <tr>
                                        <td>Show</td>
                                        <td>
                                            <select
                                                class="form-control form-control-sm"
                                                id="showpaginatetable"
                                                style="width: 100%"
                                                v-model="tableData.length"
                                                @change="getDataTable()"
                                            >
                                                <option
                                                    value="10"
                                                    selected
                                                >10</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                            </select>
                                        </td>
                                        <td>Entries</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-md-5">&nbsp;</div>
                            <div class="col-md-3">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    id="searchTable"
                                    placeholder="Cari Data ..."
                                    v-model="tableData.search"
                                    @input="searchDatatable"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <datatable
                        :columns="columns"
                        :sortKey="sortKey"
                        :sortOrders="sortOrders"
                        @sort="sortBy"
                    >
                        <tbody>
                            <tr v-if="loadingTable">
                                <td colspan="6">
                                    <i class="fas fa-spinner fa-spin"></i> Loading...
                                </td>
                            </tr>
                            <tr v-else-if="table_data == ''">
                                <td
                                    class="text-center"
                                    colspan="6"
                                >Data Tidak Tersedia</td>
                            </tr>
                            <tr
                                v-else
                                v-for="(row_data, key_data) in table_data"
                                :key="key_data"
                            >
                                <td class="text-center">
                                    <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                                    <div v-else>
                                        {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                                        }}{{ key_data + 1 }}.
                                    </div>
                                </td>
                                <td>{{ row_data.job_target.jenis_asesmen.tipe }}</td>
                                <td>{{ row_data.nama_cluster }}</td>
                                <td>{{ row_data.nama_kompetensi }}</td>
                                <td>{{ row_data.inisial_kompetensi }}</td>
                            </tr>
                        </tbody>
                    </datatable>
                </div>
                <div class="row">
                    <pagination
                        :pagination="pagination"
                        @to-page="toPage"
                    ></pagination>
                </div>
            </div>
        </div>
    </div>
</template>
