<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { encryptData } from "@/helpers/crypt.js";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Jadwal WO Generate Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_jadwal" },
      { width: "auto", label: "ID Jadwal", name: "id_jadwal" },
      { width: "auto", label: "Metode Jadwal Sebelumnya", name: "j_metode" },
      { width: "auto", label: "Job Target", name: "j_txt_kegiatan" },
      { width: "auto", label: "Peserta Diundang", name: "ju_peserta_diundang" },
      { width: "auto", label: "Peserta Hadir", name: "j_peserta_hadir" },
      { width: "auto", label: "Nama PTL", name: "j_txt_ptl" },
      { width: "auto", label: "Status", name: "j_txt_ptl" },
      { width: "auto", label: "Aksi", name: "-" },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Jadwal WO Generate Report",
      items: [
        {
          text: "Jadwal WO Generate Report",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      loadingExport: false,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      listYears: null,
      listMonths: null,
      listStatus: ["Draft", "verifikasi", "proses_ptl", "void", "Selesai"],
      listDocument: ["Lengkap", "Belum Lengkap"],
      showModalUpdateTarget: false,
      selectedIdJadwal: null,
      selectedSlug: null,
      update_file: null,
      update_tanggal_realisasi: null,
      update_no_surat: null,
      update_keterangan: null,
      file_temp: null,
      // option_jenis_asesmen: [],

      selected_jenis_asesmen: [],

      option_sub_kegiatan: [],
      selected_sub_kegiatan: [],

      option_job_target: [],
      showModalDaftarPegawaiPln: false,
      // parsing data ke table data

      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_nip: JSON.parse(localStorage.getItem("session")).user.username,
        selectedYear: new Date().getFullYear(),
        selectedMonth: 0,
        selectedStatus: 0,
        selectedDocument: 0,
        id_sub_kegiatan: "",
        selectedJobTarget: null
      },

      dataJadwalApi: [],
      searchJadwalPeserta: null,
      selectedFlag: null,
      listFlag: ["DIGILEARN", "CERMAT", "OASE", "SMILE", "LMS"],
      getJadwalAPI: {
        nip: null,
        flag: null,
        tanggal_mulai: null,
        tanggal_selesai: null,
        list_nip: null,
        limit: 10,
        page: 0,
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      showModalExportKehadiran: false,
      filterExportKehadiran: {
        tahun: 2024,
        bulan_dari: null,
        bulan_sampai: null,
        status: null,
        kehadiran: null,
        job_target: null
      },

      optionExportKehadiran: {
        tahun: [],
        bulan: [
          {
            value: null,
            text: "Semua",
          },
          {
            value: 1,
            text: "Januari",
          },
          {
            value: 2,
            text: "Februari",
          },
          {
            value: 3,
            text: "Maret",
          },
          {
            value: 4,
            text: "April",
          },
          {
            value: 5,
            text: "Mei",
          },
          {
            value: 6,
            text: "Juni",
          },
          {
            value: 7,
            text: "Juli",
          },
          {
            value: 8,
            text: "Agustus",
          },
          {
            value: 9,
            text: "September",
          },
          {
            value: 10,
            text: "Oktober",
          },
          {
            value: 11,
            text: "November",
          },
          {
            value: 12,
            text: "Desember",
          },
        ],
        status: [
          {
            value: null,
            text: "Semua",
          },
          {
            value: "Draft",
            text: "Draft",
          },
          {
            value: "proses_ptl",
            text: "Proses PTL",
          },
          {
            value: "MK Draft",
            text: "MK Draft",
          },
          {
            value: "MK Fix",
            text: "MK Fix",
          },
          {
            value: "selesai",
            text: "Selesai",
          },
        ],
        kehadiran: [
          {
            value: null,
            text: "Semua",
          },
          {
            value: 1,
            text: "Hadir",
          },
          {
            value: 0,
            text: "Tidak Hadir",
          },
        ],
      },
    };
  },
  mounted() {
    let self = this;
    self.getJobTarget();
    self.loopYears();
    self.last10Years();
    // self.cekAksesMenu();
    self.getDataTable();
    self.allMonths();
    const today = new Date();
    this.getJadwalAPI.tanggal_mulai = this.formatDate(
      this.getFirstDayOfMonth(today)
    );
    this.getJadwalAPI.tanggal_selesai = this.formatDate(
      this.getLastDayOfMonth(today)
    );
  },
  methods: {
    getJobTarget() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/job-target",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_job_target = response.data.data.referensi;
        }
      });
    },
    loopYears() {
      let self = this;
      let tahun = new Date().getFullYear();
      for (let i = 0; i < 10; i++) {
        self.optionExportKehadiran.tahun.push(tahun);
        tahun -= 1;
      }
    },
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    cekAksesMenu() {
      let self = this;
      var config_axios = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
        params: {
          role_id: self.sessionRoleId,
          link_name: self.namePathUrl,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_axios)
        .then((response) => {
          let response_data_fix = response.data.data.status_access;
          if (response.data.meta.code == 200) {
            if (response_data_fix == false) {
              Swal.fire({
                icon: "warning",
                title: "Oppss",
                text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$router.push({ name: "dashboard" });
                }
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "generate-report"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;
      // self.tableData.filter_jenis_asesmen = self.selected_jenis_asesmen.nama_jenisasesmen;
      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            if (self.tableData.draw == response_data_fix.draw) {
              self.table_data = response_data_fix.list_data.data;
              console.log(self.table_data);
              self.configPagination(response_data_fix.list_data);
              self.currentTablePage = response_data_fix.list_data.current_page;
            }
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    formatDate(date) {
      const d = new Date(date);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    last10Years() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = currentYear; i >= currentYear - 10; i--) {
        years.push(i);
      }
      this.listYears = years;
    },
    allMonths() {
      const monthsInIndonesia = [];
      const date = new Date();
      for (let month = 0; month < 12; month++) {
        date.setMonth(month);
        const monthName = date.toLocaleDateString("id-ID", { month: "long" });
        monthsInIndonesia.push(monthName);
      }
      this.listMonths = monthsInIndonesia;
    },
    onChangeJobTarget() {
      this.tableData.selectedJobTarget = this.option_job_target.id_jobtarget;
      this.getDataTable();
    },
    getFirstDayOfMonth(date) {
      return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    getLastDayOfMonth(date) {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="card-title">Semua Jadwal Wo Generate Report</div>
              </div>
              <div class="col-md-8">
                <div class="text-end">
                  <router-link
                    :to="{ name: 'add-generate-report' }"
                    class="btn btn-sm btn-success me-2"
                  ><i class="fa fa-plus"></i>Tambah Jadwal WO Generate Report</router-link>
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-1">
                    <label for="tahun">Tahun</label>
                    <select
                      class="form-control"
                      v-model="tableData.selectedYear"
                    >
                      <option value="0">Semua Tahun</option>
                      <option
                        v-for="(year, key) in this.listYears"
                        :key="key"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="bulan">Job Target</label>
                    <v-select
                      :options="option_job_target"
                      v-model="tableData.selectedJobTarget"
                      label="nama"
                      placeholder="-Pilih Job Target-"
                      @change="onChangeJobTarget($event)"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="status">Status</label>
                    <select
                      class="form-control"
                      v-model="tableData.selectedStatus"
                    >
                      <option value="0">Semua Status</option>
                      <option
                        v-for="(status, key) in this.listStatus"
                        :key="key"
                        :value="status"
                      >
                        {{ status.charAt(0).toUpperCase() + status.slice(1) }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label style="opacity: 0">Tipe</label><br />
                    <button
                      class="btn btn-info me-2"
                      v-on:click="getDataTable()"
                    >
                      <i class="fas fa-search"></i> Filter
                    </button>
                  </div>
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2 mt-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableData.length"
                            @change="getDataTable()"
                          >
                            <option
                              value="10"
                              selected
                            >10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="getDataTable()"
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <datatable
                  :columns="columns"
                  :sortKey="sortKey"
                  :sortOrders="sortOrders"
                  @sort="sortBy"
                >
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="6">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td
                        class="text-center"
                        colspan="12"
                      >
                        Data Tidak Tersedia
                      </td>
                    </tr>
                    <tr
                      v-else
                      v-for="(row_data, key_data) in table_data"
                      :key="key_data"
                    >
                      <td class="text-center">
                        <div v-if="key_data + 1 == 10">
                          {{ currentTablePage }}0.
                        </div>
                        <div v-else>
                          {{
                            currentTablePage - 1 != 0
                              ? currentTablePage - 1
                              : ""
                          }}{{ key_data + 1 }}.
                        </div>
                      </td>
                      <td>#{{ row_data.idjadwal }}</td>
                      <td>
                        {{ row_data.j_metode }}
                      </td>
                      <td>
                        <ul
                          v-for="(row_job_target,
                            key_job_target)
                            in
                            row_data.jadwal_job_target"
                          :key="key_job_target"
                        >
                          <li>{{ row_job_target.m_jobtarget_nama }}</li>
                        </ul>
                      </td>
                      <td>
                        {{ row_data.jadwal_unit_sum_ju_peserta_diundang }}
                      </td>
                      <td>
                        {{
                          row_data.jadwal_asesi_p_sum_ja_kehadiran
                            ? row_data.jadwal_asesi_p_sum_ja_kehadiran
                            : "0"
                        }}
                      </td>
                      <td>
                        {{ row_data.j_txt_ptl }}
                      </td>
                      <td>
                        <span
                          style="white-space: nowrap"
                          class="badge bg-danger"
                          v-if="row_data.j_status == 'void'"
                        >
                          Void
                        </span>
                        <span
                          style="white-space: nowrap"
                          class="badge bg-info"
                          v-if="row_data.j_status == 'Draft'"
                        >
                          Draft
                        </span>
                        <span
                          style="white-space: nowrap"
                          class="badge bg-warning"
                          v-else-if="row_data.j_status == 'verifikasi'"
                        >
                          Verifikasi Jadwal
                        </span>
                        <span
                          style="white-space: nowrap"
                          class="badge bg-success"
                          v-else-if="row_data.j_status == 'proses_ptl'"
                        >
                          Proses Jadwal ke PTL
                        </span>
                        <span
                          style="white-space: nowrap"
                          class="badge bg-primary"
                          v-else-if="row_data.j_status == 'selesai'"
                        >
                          Selesai
                        </span>
                        <span
                          style="white-space: nowrap"
                          class="badge bg-info"
                          v-else-if="row_data.j_status == 'MK Fix'"
                        >
                          MK Fix
                        </span>
                        <span
                          style="white-space: nowrap"
                          class="badge bg-danger"
                          v-else-if="row_data.j_status == 'batal'"
                        >
                          Batal
                        </span>
                        <span v-else>
                          {{ row_data.j_status }}
                        </span>
                      </td>
                      <td class="text-center">
                        <div class="btn-group">
                          <router-link
                            :to="{
                              name: 'detail-generate-report',
                              params: {
                                id: encryptedUrl(row_data.idjadwal.toString()),
                              },
                            }"
                            class="btn btn-success btn-sm"
                          ><i class="fa fa-eye"></i> Detail
                          </router-link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </datatable>
              </div>
            </div>
            <pagination
              :pagination="pagination"
              @to-page="toPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
