// import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
  },
  {
    path: "/",
    name: "dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { authRequired: true },
    component: () => import("./views/account/profile"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: { authRequired: true },
    component: () => import("./views/account/logout"),
  },

  // routes Master

  // routes master role
  {
    path: "/master/role",
    name: "all-role",
    meta: { authRequired: true },
    component: () => import("./views/master/role/all-role"),
  },
  {
    path: "/master/role/tambah",
    name: "add-role",
    meta: { authRequired: true },
    component: () => import("./views/master/role/add-role"),
  },
  {
    path: "/master/role/edit/:id",
    name: "edit-role",
    meta: { authRequired: true },
    component: () => import("./views/master/role/edit-role"),
  },

  // routes master menu
  {
    path: "/master/menu",
    name: "all-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/all-menu"),
  },
  {
    path: "/master/menu/tambah",
    name: "add-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/add-menu"),
  },
  {
    path: "/master/menu/edit/:id",
    name: "edit-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/edit-menu"),
  },
  {
    path: "/master/menu/ordering",
    name: "ordering-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/ordering-menu"),
  },
  {
    path: "/master/menu/role-menu",
    name: "role-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/role-menu"),
  },

  // routes master user
  {
    path: "/master/user",
    name: "all-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/all-user"),
  },
  {
    path: "/master/user/tambah",
    name: "add-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/add-user"),
  },
  {
    path: "/master/user/edit/:id",
    name: "edit-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/edit-user"),
  },

  // routes master konfigurasi
  {
    path: "/master/konfigurasi",
    name: "all-konfigurasi",
    meta: { authRequired: true },
    component: () => import("./views/master/konfigurasi/all-konfigurasi"),
  },

  //routes master pohon profesi
  {
    path: "/master/pohon-profesi",
    name: "all-pohon-profesi",
    meta: { authRequired: true },
    component: () => import("./views/master/pohon-profesi/all-pohon-profesi"),
  },

  //routes master menu page
  {
    path: "/master/page",
    name: "all-page",
    meta: { authRequired: true },
    component: () => import("./views/master/page/all-page"),
  },
  {
    path: "/master/page/tambah",
    name: "add-page",
    meta: { authRequired: true },
    component: () => import("./views/master/page/add-page"),
  },
  {
    path: "/master/page/edit/:id",
    name: "edit-page",
    meta: { authRequired: true },
    component: () => import("./views/master/page/edit-page"),
  },

  // (WIP) routes menu dynamic render
  {
    path: "/render",
    name: "render",
    meta: { authRequired: true },
    component: () => import("./views/render"),
  },

  //routes menu penjadwalan konversi
  {
    path: "/master/jadwal-pegpog",
    name: "all-data",
    meta: { authRequired: true },
    component: () => import("./views/master/penjadwalan-pegpog/all-data"),
  },
  {
    path: "/master/jadwal-pegpog/:id",
    name: "detail-penjadwalan",
    meta: { authRequired: true },
    component: () => import("./views/master/penjadwalan-pegpog/detail-jadwal-pegpog"),
  },
  {
    path: "/render",
    name: "render",
    meta: { authRequired: true },
    component: () => import("./views/render"),
  },

  {
    path: "/loginportal",
    name: "login_portal",
    component: () => import("./views/loginportal/portal"),
  },
  {
    path: "/master/jadwal-pegpog/add-jadwal",
    name: "add-jadwal-pegpog",
    component: () => import("./views/master/penjadwalan-pegpog/add-jadwal-pegpog.vue"),
  },
  {
    path: "/master/jadwal-pegpog/add-jadwal/:id",
    name: "add-jadwal-pegpog-psikologi",
    component: () => import("./views/master/penjadwalan-pegpog/add-jadwal-pegpog-psikologi.vue"),
  },
  {
    path: "/master/jadwal-pegpog/edit-jadwal/:id",
    name: "edit-jadwal-pegpog",
    component: () => import("./views/master/penjadwalan-pegpog/edit-jadwal-pegpog.vue"),
  },
  {
    path: "/master/penilaian-pegpog",
    name: "all-penilaian-pegpog",
    component: () => import("./views/master/penilaian-pegpog/all-penjadwalan.vue"),
  },
  {
    path: "/master/penilaian-pegpog/list-jadwal",
    name: "all-jadwal-penilaian-pegpog",
    component: () => import("./views/master/penilaian-pegpog/all-penjadwalan.vue"),
  },
  {
    path: "/master/penilaian-pegpog/detail/:id",
    name: "detail-jadwal-penilaian-pegpog",
    meta: { authRequired: true },
    component: () => import("./views/master/penilaian-pegpog/detail-jadwal-pegpog.vue"),
  },
  {
    path: "/master/penjadwalan-pegpog/:id/add-jadwal-asesmen",
    name: "add-jadwal-asesmen",
    props: {
      header: true,
      content: true,
    },
    meta: { authRequired: true },
    component: () => import("./views/master/penjadwalan-pegpog/add-jadwal-asesmen/add-jadwal-asesmen.vue"),
  },
  {
    path: "/master/penjadwalan-pegpog/:id/add-jadwal-potensi",
    name: "add-jadwal-potensi",
    props: {
      header: true,
      content: true,
    },
    meta: { authRequired: true },
    component: () => import("./views/master/penjadwalan-pegpog/add-jadwal-potensi/add-jadwal-potensi.vue"),
  },
  //asesemen-center
  {
    path: "/penjadwalan/asesmen-center",
    name: "all-asesmen-center",
    component: () => import("./views/master/asesmen-center/all-data.vue"),
  },
  {
    path: "/penjadwalan/asesmen-center/add-data",
    name: "add-asesmen-center",
    component: () => import("./views/master/asesmen-center/add-ac.vue"),
  },
  {
    path: "/penjadwalan/asesmen-center/detail/:id",
    name: "detail-asesmen-center",
    component: () => import("./views/master/asesmen-center/detail-ac/detail-ac-jadwal.vue"),
  },
  {
    path: "/penjadwalan/asesmen-center/detail/import-asesi/:id",
    name: "import-asesi-asesmen-center",
    component: () => import("./views/master/asesmen-center/detail-ac/asesi/importasesi-detail-ac.vue"),
  },
  {
    path: "/penjadwalan/asesmen-center/validasi-asesi/:id",
    name: "validasi-asesi-asesmen-center",
    component: () => import("./views/master/asesmen-center/detail-ac/asesi/validasi-asesi-ac.vue"),
  },
  {
    path: "/penjadwalan/asesmen-psikologi/validasi-psikologi/:id",
    name: "validasi-asesi-asesmen-psikologi",
    component: () => import("./views/master/penjadwalan/asesmen-psikologi/detail-ap/asesi/validasi-asesi-ap.vue"),
  },
  {
    path: "/penjadwalan/rekrutmen/validasi-rekrutmen/:id",
    name: "validasi-asesi-rekrutmen",
    component: () => import("./views/master/penjadwalan/rekrutmen/detail-rekrutmen/asesi/validasi-asesi-rekrutmen.vue"),
  },
  {
    path: "/penjadwalan/asesmen-center/add-data-combo",
    name: "add-asesmen-center-combo",
    component: () => import("./views/master/asesmen-center/add-ac-combo.vue"),
  },
  {
    path: "/penjadwalan/asesmen-center/edit-jadwal/:id",
    name: "edit-asesmen-center",
    component: () => import("./views/master/asesmen-center/edit-jadwal-ac.vue"),
  },
  //asesmen-psikologi
  {
    path: "/penjadwalan/asesmen-psikologi",
    name: "all-asesmen-psikologi",
    component: () => import("./views/master/penjadwalan/asesmen-psikologi/list-jadwal.vue"),
  },
  {
    path: "/penjadwalan/asesmen-psikologi/detail/:id",
    name: "detail-asesmen-psikologi",
    component: () => import("./views/master/penjadwalan/asesmen-psikologi/detail-ap/detail-ap-jadwal.vue"),
  },
  {
    path: "/penjadwalan/asesmen-psikologi/detail/import-asesi/:id",
    name: "import-asesi-asesmen-psikologi",
    component: () => import("./views/master/penjadwalan/asesmen-psikologi/detail-ap/asesi/importasesi-detail-ap.vue"),
  },
  {
    path: "/penjadwalan/add-jadwal-psikologi",
    name: "add-jadwal-psikologi",
    meta: { authRequired: true },
    component: () => import("./views/master/penjadwalan/asesmen-psikologi/add-jadwal.vue"),
  },
  {
    path: "/penjadwalan/asesmen-psikologi/edit-jadwal/:id",
    name: "edit-asesmen-psikologi",
    component: () => import("./views/master/penjadwalan/asesmen-psikologi/edit-jadwal-ap.vue"),
  },
  //rekrutmen
  {
    path: "/penjadwalan/rekrutmen",
    name: "all-rekrutmen",
    component: () => import("./views/master/penjadwalan/rekrutmen/list-jadwal.vue"),
  },
  {
    path: "/penjadwalan/rekrutmen/detail/:id",
    name: "detail-rekrutmen",
    component: () => import("./views/master/penjadwalan/rekrutmen/detail-rekrutmen/detail-rekrutmen-jadwal.vue"),
  },
  {
    path: "/penjadwalan/rekrutmen/detail/import-asesi/:id",
    name: "import-asesi-rekrutmen",
    component: () => import("./views/master/penjadwalan/rekrutmen/detail-rekrutmen/asesi/import-asesi-detail-rekrutmen.vue"),
  },
  {
    path: "/penjadwalan/add-jadwal-rekrutmen",
    name: "add-jadwal-rekrutmen",
    meta: { authRequired: true },
    component: () => import("./views/master/penjadwalan/rekrutmen/add-jadwal.vue"),
  },
  {
    path: "/penjadwalan/rekrutmen/edit-jadwal/:id",
    name: "edit-rekrutmen",
    component: () => import("./views/master/penjadwalan/rekrutmen/edit-jadwal-rekrutmen.vue"),
  },
  //penjadwalan hygiene
  {
    path: "/penjadwalan/penjadwalan-hygiene",
    name: "all-penjadwalan-hygiene",
    component: () => import("./views/master/penjadwalan/penjadwalan-hygiene/list-jadwal.vue"),
  },
  {
    path: "/penjadwalan/penjadwalan-hygiene/detail/:id",
    name: "detail-penjadwalan-hygiene",
    component: () => import("./views/master/penjadwalan/penjadwalan-hygiene/detail-hygiene/detail-hygiene-jadwal.vue"),
  },
  {
    path: "/master/mk",
    name: "all-mk",
    component: () => import("./views/master/mk/all-data.vue"),
  },
  {
    path: "/master/mk/:id",
    name: "detail-mk",
    authRequired: true,
    component: () => import("./views/master/mk/form-data.vue"),
  },

  {
    path: "/rekap-qc",
    name: "rekap-qc",
    authRequired: true,
    component: () => import("./views/rekap-qc/rekap-qc.vue"),
  },

  {
    path: "/detail-rekap-qc-ptl/:idjadwal/:iduser",
    name: "detail-rekap-qc-ptl",
    authRequired: true,
    component: () => import("./views/rekap-qc/detail/qc-ptl/detail-rekap-qc-ptl.vue"),
  },

  {
    path: "/detail-rekap-qc-asesor-ac/:idjadwal/:iduser",
    name: "detail-rekap-qc-asesor-ac",
    authRequired: true,
    component: () => import("./views/rekap-qc/detail/qc-asesor-ac/detail-rekap-qc-asesor-ac.vue"),
  },
  {
    path: "/detail-rekap-qc-asesor-pq/:idjadwal/:iduser",
    name: "detail-rekap-qc-asesor-pq",
    authRequired: true,
    component: () => import("./views/rekap-qc/detail/qc-asesor-pq/detail-rekap-qc-asesor-pq.vue"),
  },

  {
    path: "/detail-rekap-qc-asesor-role-player/:idjadwal/:iduser",
    name: "detail-rekap-qc-asesor-role-player",
    authRequired: true,
    component: () => import("./views/rekap-qc/detail/qc-asesor-role-player/detail-rekap-qc-asesor-role-player.vue"),
  },

  {
    path: "/detail-rekap-qc-petugas-kelas-ac/:idjadwal/:iduser",
    name: "detail-rekap-qc-petugas-kelas-ac",
    authRequired: true,
    component: () => import("./views/rekap-qc/detail/qc-petugas-kelas-ac/detail-rekap-qc-petugas-kelas-ac.vue"),
  },

  {
    path: "/detail-rekap-qc-petugas-kelas-ap/:idjadwal/:iduser",
    name: "detail-rekap-qc-petugas-kelas-ap",
    authRequired: true,
    component: () => import("./views/rekap-qc/detail/qc-petugas-kelas-ap/detail-rekap-qc-petugas-kelas-ap.vue"),
  },

  //generate report
  {
    path: "/penjadwalan/generate-report",
    name: "all-generate-report",
    component: () => import("./views/master/penjadwalan/generate-report/list-gr.vue"),
  },
  {
    path: "/penjadwalan/generate-report/add",
    name: "add-generate-report",
    component: () => import("./views/master/penjadwalan/generate-report/add-gr.vue"),
  },
  
  {
    path: "/penjadwalan/generate-report/detail/:id",
    name: "detail-generate-report",
    component: () => import("./views/master/penjadwalan/generate-report/detail-gr/detail-gr-jadwal.vue"),
  },

  {
    path: "/penjadwalan/generate-report/edit/:id",
    name: "edit-generate-report",
    component: () => import("./views/master/penjadwalan/generate-report/edit-jadwal-gr.vue"),
  },
];
