<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["data_asesi"],
    data() {
        return {
            table_data: [],
            table_data_atas: [],
            loadingTable: false,
        };
    },
    mounted() {
        let self = this;
        self.getDataAtas();
        // self.getData();
    },
    methods: {
        getDataAtas() {
            let self = this;
            var config = {
                method: "get",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "generate-report/detail-soft-competency-manajerial-atas",
                params: {
                    nip_asesi: this.$props.data_asesi.nip,
                    id_jadwal: this.$props.data_asesi.idjadwal,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.table_data_atas = response_data_fix;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        getData(kompetensi_id) {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "generate-report/detail-soft-competency-manajerial",
                params: {
                    kompetensi_id: kompetensi_id,
                    nip_asesi: this.$props.data_asesi.nip,
                    id_jadwal: this.$props.data_asesi.idjadwal,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.table_data = response_data_fix;
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
    },
};
</script>
<template>
    <div>
        <b>{{ data_asesi.jenis_asesmen }} </b> - <b>{{ data_asesi.tanggal_mulai_pelaksanaan }}</b>
        <div class="row">
            <div class="col-6">
                <div class="table-responsive">
                    <table class="table table-bordered mb-0 mt-2">
                        <thead
                            class="bg-dark text-white"
                            style="padding: 10px"
                        >
                            <th style="padding: 5px">Kompetensi</th>
                            <th style="padding: 5px">Rating</th>
                            <th style="padding: 5px">Aksi</th>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(row_data, key) in table_data_atas"
                                :key="key"
                            >
                                <td>{{ row_data.kompetensi_nama }}</td>
                                <td>{{ row_data.rating }}</td>
                                <td>
                                    <button
                                        type="button"
                                        class="btn btn-info btn-sm"
                                        @click=getData(row_data.kompetensi_id)
                                    >
                                        <i class="fa fa-eye"></i> Detail
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-6">

                <div class="table-responsive">
                    <table class="table table-bordered mb-0 mt-2">
                        <thead
                            class="bg-dark text-white"
                            style="padding: 10px"
                        >
                            <th style="padding: 5px">Keyb Name</th>
                            <th style="padding: 5px">Value</th>
                        </thead>
                        <tbody>
                            <!-- <template v-for="mSale in table_data" :key="mSale"> -->
                            <tr v-if="loadingTable">
                                <td colspan="3">
                                    <i class="fas fa-spinner fa-spin"></i> Loading...
                                </td>
                            </tr>
                            <tr v-else-if="table_data.length == 0">
                                <td colspan="3">
                                    Data tidak tersedia
                                </td>
                            </tr>
                            <tr
                                v-else
                                v-for="(row_data, key) in table_data"
                                :key="key"
                            >
                                <td>{{ row_data.keyb_name }}</td>
                                <td>{{ row_data.value }}</td>
                            </tr>
                            <!-- </template> -->
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <!-- <table
      border="1"
      class="table-responsive"
      style="border-collapse: collapse"
    >
      <thead>
        <th>Product</th>
        <th>Month</th>
        <th>Unit price</th>
        <th>No. sold</th>
      </thead>
      <tbody>
        <template v-for="mSale in salesData.monthlySales" :key="mSale">
          <tr v-for="(month, key) in mSale.months" :key="key">
            <td v-if="key == 0" :rowspan="mSale.months.length">
              {{ mSale.product }}
            </td>
            <td>{{ month.month }}</td>
            <td>{{ month.unitPrice }}</td>
            <td>{{ month.unitsSold }}</td>
          </tr>
        </template>
      </tbody>
    </table> -->
    </div>
</template>
