<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { encryptData } from "@/helpers/crypt.js";
import { containsDisallowedCharacters } from "../../../../../utils/check-disallowed-character";
/**
 * Dashboard Component
 */

export default {
  page: {
    title: "Tambah Jadwal WO Generate Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  props: {
    dataPeserta: {
      type: String,
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      data_peserta: [],
      data_jadwal: [],

      form_data: {
        j_metode: "",
        j_tipe: "",
        form_jobtarget: [
          {
            id_jobtarget: "",
            nama: "",
            id_jenisasesmen: "",
          },
        ],
        m_user_id_ptl: "",
        m_user_nip_ptl: "",
        j_txt_ptl: "",
        j_json_petugas: "",
        j_impact: "NO",
        j_keterangan: "",
        m_jadwal_kegiatan_idm_kegiatan: "", //
        j_txt_kegiatan: "",
        m_dirkom_idm_dirkom: "",
        j_txt_dirkom: "",
        user_id: localStorage.getItem("session_user_id"),
        user_name: localStorage.getItem("session_name"),
        m_id_subkegiatan: "",
        m_nama_kegiatan: "",
        j_json_alattes: "",
        m_batre_test_bt_id: "",
        j_txt_batre_test: "",
      },

      option_unit: [],
      selected_unit: "",

      option_lokasi: [],
      checkedLokasi: false,

      selected_petugas: [],
      selected_tugas: [],

      option_tugas: [],
      temp_id_jadwal: "",
      selected_metode: "",

      option_kegiatan: [],
      selected_kegiatan: "",

      option_sub_kegiatan: [],
      selected_sub_kegiatan: "",

      option_jenis_asesmen: [],
      seleceted_jenis_asesmen: "",

      option_batre_test: [],
      seleceted_batre_test: "",

      option_alat_test: [],

      option_materi: [],
      seleceted_materi: "",

      option_checklist: [],
      selected_checklist: "",

      option_ptl: [],
      selected_ptl: [],

      json_fix_peserta: [],

      form_unit: [
        {
          m_unit_slug: "",
          m_unit_idm_unit: "",
          jadwal_idjadwal: null,
          ju_txt_unit: "",
          jumlah_diundang: "",
        },
      ],
      selected_unit_peserta: [],
      selected_jumlah_peserta: [],

      //Jenis Asesmen
      readOnlyJA: true,

      lokasi_text: "",
      json_petugas: "",

      selected_jobtarget: [],
      option_jobtarget: [],
      is_show_job_target_list: false,
    };
  },

  mounted() {
    let self = this;
    self.searchUnit(true);
    self.searchKegiatan(true);
    self.searchSubKegiatan(true);
    // self.searchJenisAsesmen(true);
    // self.searchMateri(true);
    self.searchBatreTest(true);
    self.searchPTL(true);
    self.searchTugas();
    // self.searchJobTarget();
  },

  created() {
    // this.data_peserta = JSON.parse(window.history.state.data_peserta);
    // this.data_jadwal = JSON.parse(window.history.state.data_jadwal);
    // this.form_data.m_user_id_ptl = this.data_jadwal.m_user_id_ptl;
    // this.form_data.m_user_nip_ptl = this.data_jadwal.m_user_nip_ptl;
    // this.form_data.j_txt_ptl = this.data_jadwal.j_txt_ptl;
    // this.form_data.j_json_petugas = this.data_jadwal.j_json_petugas;
    // this.json_petugas = JSON.parse(this.data_jadwal.j_json_petugas);
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },

    //Dropdown Unit
    onSearchUnit(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUnit(loading, search, this);
      }
    },
    searchTugas: function (search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/tugas",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_tugas = response.data.data.referensi;
        }
      });
    },
    searchUnit: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/unit",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_unit = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeUnit() {
      let self = this;
      self.form_data.m_unit_idm_unit = self.selected_unit.m_unit_idm_unit;
      self.form_data.j_txt_unit = self.selected_unit.ju_txt_unit;
    },
    addFormJobTarget() {
      this.form_data.form_jobtarget.push({
        id_jobtarget: "",
        nama: "",
        id_jenisasesmen: "",
      });
    },
    clearFormJobTarget(index) {
      this.selected_jobtarget.splice(index, 1);
      this.form_data.form_jobtarget.splice(index, 1);
    },
    onChangeJobTarget(index) {
      this.form_data.form_jobtarget[index].id_jobtarget = this.selected_jobtarget[index].id_jobtarget;
      this.form_data.form_jobtarget[index].nama = this.selected_jobtarget[index].nama;
      this.form_data.form_jobtarget[index].id_jenisasesmen = this.selected_jobtarget[index].id_jenisasesmen;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    //Pilih Lokasi
    onSearchLokasi(search, loading) {
      if (search.length) {
        loading(true);
        this.searchLokasi(loading, search, this);
      }
    },
    //Batre Test
    onSearchBatreTest(search, loading) {
      if (search.length) {
        loading(true);
        this.searchBatreTest(loading, search, this);
      }
    },
    searchBatreTest: function (loading, search) {
      let self = this;
      var tipe = null;
      if (self.form_data.j_metode == 'Psikologi') {
        tipe = 'Rekrutmen';
      } else if (self.form_data.j_metode == 'Assessment') {
        tipe = 'AC';
      }
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-test-gr",
        params: {
          keyword: search,
          tipe: tipe,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_batre_test = response.data.data.referensi;

          loading(false);
        }
      });
    },
    onChangeBatreTest() {
      let self = this;
      self.form_data.m_batre_test_bt_id = self.seleceted_batre_test.bt_id;
      self.form_data.j_txt_batre_test = self.seleceted_batre_test.bt_nama;
      self.getAlatTes();
    },

    //alattes
    getAlatTes() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-alat-test",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          id_batretest: self.seleceted_batre_test.bt_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_alat_test = response_data.data.referensi;
            self.form_data.j_json_alattes = self.option_alat_test;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    //Kegiatan
    onSearchKegiatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchKegiatan(loading, search, this);
      }
    },
    searchKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kegiatan",
        params: {
          keyword: search,
          tipe: "ass",
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_kegiatan = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeKegiatan() {
      let self = this;
      self.form_data.m_jadwal_kegiatan_idm_kegiatan = self.selected_kegiatan.id;
      self.form_data.j_txt_kegiatan = self.selected_kegiatan.kegiatan;
      self.searchJenisAsesmen(true);
      self.form_data.m_dirkom_idm_dirkom = null;
      self.seleceted_jenis_asesmen = null;
      self.readOnlyJA = false;
    },

    // Sub Kegiatan
    onSearchSubKegiatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchSubKegiatan(loading, search, this);
      }
    },
    searchSubKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/sub-kegiatan",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_sub_kegiatan = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeSubKegiatan() {
      let self = this;
      self.form_data.m_id_subkegiatan = self.selected_sub_kegiatan.id_subkegiatan;
      self.form_data.m_nama_kegiatan = self.selected_sub_kegiatan.nama_subkegiatan;
    },

    //Jenis Asesmen
    onSearchJenisAsesmen(search, loading) {
      if (search.length) {
        loading(true);
        this.searchJenisAsesmen(loading, search, this);
      }
    },
    searchJenisAsesmen: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
        params: {
          keyword: search,
          id_kegiatan: self.selected_kegiatan.id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_jenis_asesmen = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeJenisAsesmen() {
      let self = this;
      self.form_data.m_dirkom_idm_dirkom = self.seleceted_jenis_asesmen.id;
      self.form_data.j_txt_dirkom = self.seleceted_jenis_asesmen.nama_jenisasesmen;
      self.is_show_opsi_job_target = true;
      self.searchJobTarget();
      self.seleceted_materi = null;
    },

    submitForm() {
      let self = this;
      if (self.selected_sub_kegiatan) {
        self.form_data.m_id_subkegiatan = self.selected_sub_kegiatan.id_subkegiatan;
        self.form_data.m_nama_kegiatan = self.selected_sub_kegiatan.nama_subkegiatan;
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "generate-report/store",
        data: self.form_data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            // self.storeDataPetugas(response.data.data[0].idjadwal);
            self.temp_id_jadwal = response.data.data[0].idjadwal;
            self.storeDataUnit(self.temp_id_jadwal);
          }
        })
        .catch((error) => {
          Swal.close();
          console.log(error);
        });
    },
    addFormUnit() {
      this.form_unit.push({
        m_unit_slug: "",
        m_unit_idm_unit: "",
        jadwal_idjadwal: null,
        ju_txt_unit: "",
        jumlah_diundang: "",
      });
    },
    clearFormUnit(index) {
      this.form_unit[index].m_unit_slug = "";
      this.form_unit[index].m_unit_idm_unit = "";
      this.form_unit[index].jadwal_idjadwal = null;
      this.form_unit[index].ju_txt_unit = "";
      this.form_unit[index].jumlah_diundang = "";
      this.selected_unit_peserta.splice(index, 1);
      this.selected_jumlah_peserta.splice(index, 1);
      this.form_unit.splice(index, 1);
    },

    onSearchPTL(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPTL(loading, search, this);
      }
    },
    searchPTL: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_ptl = response.data.data.referensi;
          loading(false);
        }
      });
    },

    searchJobTarget: function (search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/job-target",
        params: {
          keyword: search,
          id_jenis_asesmen: self.seleceted_jenis_asesmen.id
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_jobtarget = response.data.data.referensi;
        }
      });
    },

    validasi() {
      if (this.form_unit.length < 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unit Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_metode == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Metode Jadwal Sebelumnya Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_txt_ptl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ptl Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_txt_tgl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.j_json_alattes == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Alat Tes Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_txt_batre_test == "" || this.form_data.m_batre_test_bt_id == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Batre Test Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_impact == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Adverse Impact Tidak Boleh Kosong",
        });
        return false;
      }
      // j_keterangan
      if (this.form_data.j_keterangan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Keterangan Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_kegiatan
      if (this.form_data.j_txt_kegiatan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Kegiatan Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_dirkom
      if (this.form_data.j_txt_dirkom == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "jenis assesmen Tidak Boleh Kosong",
        });
        return false;
      }
      if (containsDisallowedCharacters(this.form_data.j_keterangan)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return false; // Exit the function if validation fails
      } else {
        Swal.fire({
          title: "Apakah Anda Yakin?",
          text: "Data Akan Disimpan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Simpan!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm();
          }
        });
      }
    },

    storeDataUnit(idjadwal) {
      let self = this;
      for (let index = 0; index < self.form_unit.length; index++) {
        self.form_unit[index].jadwal_idjadwal = idjadwal;
      }
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/store-unit",
        data: {
          idjadwal,
          json_unit: self.form_unit,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.storeChecklist();
        }
      });
    },
    storeChecklist(idjadwal) {
      let self = this;

      for (let index = 0; index < self.form_unit.length; index++) {
        self.form_unit[index].jadwal_idjadwal = idjadwal;
      }
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/store-checklist",
        data: {
          id_jadwal: self.temp_id_jadwal,
          json_checklist: self.option_checklist,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Berhasil Menambahkan Jadwal",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            self.$router.push({
              name: "detail-generate-report",
              params: { id: this.encryptedUrl(self.temp_id_jadwal.toString()) },
            });
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
            }
          });
        }
      });
    },

    onChangeUnitPeserta(index) {
      let self = this;
      self.form_unit[index].ju_txt_unit = self.selected_unit_peserta[index].ju_txt_unit;
      self.form_unit[index].m_unit_idm_unit = self.selected_unit_peserta[index].m_unit_idm_unit;
      self.form_unit[index].m_unit_slug = self.selected_unit_peserta[index].m_unit_slug;
    },

    onChangePTL() {
      let self = this;
      self.form_data.m_user_id_ptl = self.selected_ptl.iduser;
      self.form_data.m_user_nip_ptl = self.selected_ptl.u_nip;
      self.form_data.j_txt_ptl = self.selected_ptl.u_nama;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <h4>Tambah Jadwal WO Generate Report</h4>
              </div>
              <div class="row mt-4">
                <div
                  class="col-md-6"
                  style="border-right: 2px solid #ddd"
                >
                  <b-form-group
                    class="mb-3"
                    label="Metode Jadwal Sebelumnya"
                    label-for="form-metode"
                  >
                    <v-select
                      :options="['Assessment', 'Psikologi']"
                      v-model="form_data.j_metode"
                      placeholder="-Pilih Metode Jadwal Sebelumnya-"
                      @update:modelValue="searchBatreTest()"
                    ></v-select>
                  </b-form-group>
                  <b-form-group
                    class="mb-3"
                    label="Tipe Jadwal"
                    label-for="form-metode"
                  >
                    <v-select
                      :options="['Kompetensi & Potensi', 'Kompetensi', 'Potensi']"
                      v-model="form_data.j_tipe"
                      placeholder="-Pilih Tipe Jadwal-"
                    ></v-select>
                  </b-form-group>


                  <!-- Form Kegiatan -->
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        class="mb-3"
                        label="Kegiatan"
                        label-for="form-unit-pelaksana"
                      >
                        <v-select
                          :options="option_kegiatan"
                          label="kegiatan"
                          v-model="selected_kegiatan"
                          placeholder="-Pilih Kegiatan-"
                          @search="onSearchKegiatan"
                          @update:modelValue="onChangeKegiatan()"
                        ></v-select>
                      </b-form-group>
                    </div>
                  </div>
                  <!-- Form Sub Kegiatan -->
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        class="mb-3"
                        label="Sub Kegiatan"
                        label-for="form-unit-pelaksana"
                      >
                        <v-select
                          :options="option_sub_kegiatan"
                          label="nama_subkegiatan"
                          v-model="selected_sub_kegiatan"
                          placeholder="-Pilih Sub Kegiatan-"
                          @search="onSearchSubKegiatan"
                          @update:modelValue="onChangeSubKegiatan()"
                        ></v-select>
                      </b-form-group>
                    </div>
                  </div>
                  <b-form-group
                    class="mb-3"
                    label="Jenis Asesmen"
                    label-for="form-jenis-asesmen"
                  >
                    <v-select
                      :options="option_jenis_asesmen"
                      label="nama_jenisasesmen"
                      v-model="seleceted_jenis_asesmen"
                      placeholder="-Pilih Jenis Asesmen-"
                      @search="onSearchJenisAsesmen"
                      @update:modelValue="onChangeJenisAsesmen()"
                      :disabled="readOnlyJA"
                    ></v-select>
                  </b-form-group>
                  <div
                    class="row"
                    v-for="(value_jobtarget, key_jobtarget) in form_data.form_jobtarget"
                    :key="key_jobtarget"
                  >
                    <div class="col-md-10">
                      <b-form-group
                        class="mb-3"
                        label="Job Target"
                      >
                        <v-select
                          :options="option_jobtarget"
                          v-model="selected_jobtarget[key_jobtarget]"
                          label="nama"
                          placeholder="-Pilih Job Target-"
                          @update:modelValue="onChangeJobTarget(key_jobtarget)"
                        ></v-select>
                      </b-form-group>
                    </div>
                    <div
                      class="col-md-2 d-flex align-items-center"
                      v-if="key_jobtarget == 0"
                    >
                      <button
                        class="btn btn-success mt-2"
                        type="button"
                        @click="addFormJobTarget()"
                      >
                        <span><i class="fa fa-plus"></i> </span>
                      </button>
                    </div>
                    <div
                      class="col-md-2 d-flex align-items-center"
                      v-if="key_jobtarget > 0"
                    >
                      <button
                        class="btn btn-danger mt-2"
                        @click="clearFormJobTarget(key_jobtarget)"
                        type="button"
                      >
                        <span><i class="fa fa-minus"></i> </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <!-- Form Batre Test -->
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        class="mb-3"
                        label="Batre Test"
                        label-for="form-batre-test"
                      >
                        <v-select
                          :options="option_batre_test"
                          label="bt_nama"
                          v-model="seleceted_batre_test"
                          placeholder="-Pilih Batre Test-"
                          @search="onSearchBatreTest"
                          @update:modelValue="onChangeBatreTest()"
                        ></v-select>
                      </b-form-group>
                    </div>
                  </div>
                  <!-- Form Alat Tes -->
                  <div
                    class="row row-cols-2"
                    v-if="seleceted_batre_test != ''"
                  >
                    <b-form-group
                      class="mb-3"
                      label="Metode"
                      label-for="form-metode"
                      v-for="alattes in option_alat_test"
                      :key="alattes.m_alat_tes_id"
                    >
                      <b-form-input
                        id="form-metode"
                        class="bg-secondary text-white"
                        type="text"
                        readonly
                        v-model="alattes.m_alat_tes_nama"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <!-- Form Metode -->
                  <b-form-group
                    class="mb-3"
                    label="PTL"
                    label-for="ptl"
                  >
                    <v-select
                      :options="option_ptl"
                      label="label"
                      v-model="selected_ptl"
                      placeholder="-Pilih PTL-"
                      @search="onSearchPTL"
                      @update:modelValue="onChangePTL()"
                    ></v-select>
                  </b-form-group>
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        class="mb-3"
                        label="Keterangan"
                        label-for="keterangan"
                      >
                        <b-form-textarea
                          id="keterangan"
                          type="textarea"
                          v-model="form_data.j_keterangan"
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                  </div>

                  <div
                    class="row"
                    v-for="(form, k) in form_unit"
                    :key="k"
                  >
                    <div class="col-md-5">
                      <b-form-group
                        class="mb-3"
                        label="Unit Peserta"
                        label-for="ptl"
                      >
                        <v-select
                          :options="option_unit"
                          label="m_unit_slug"
                          v-model="selected_unit_peserta[k]"
                          placeholder="-Pilih Unit-"
                          @search="onSearchUnit"
                          @update:modelValue="onChangeUnitPeserta(k)"
                        ></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-5">
                      <b-form-group
                        class="mb-3"
                        label="Peserta Diundang"
                        label-for="pesertaDiundang"
                      >
                        <b-form-input
                          id="pesertaDiundang"
                          placeholder="Masukkan jumlah peserta diundang"
                          type="text"
                          v-model="form_unit[k].jumlah_diundang"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div
                      class="col-md-2 d-flex align-items-center"
                      v-if="k == 0"
                    >
                      <button
                        class="btn btn-success mt-2"
                        type="button"
                        @click="addFormUnit()"
                      >
                        <span><i class="fa fa-plus"></i> </span>
                      </button>
                    </div>
                    <div
                      class="col-md-2 d-flex align-items-center"
                      v-if="k > 0"
                    >
                      <button
                        class="btn btn-danger mt-2"
                        @click="clearFormUnit(k)"
                        type="button"
                      >
                        <span><i class="fa fa-minus"></i> </span>
                      </button>
                    </div>
                  </div>


                  <b-form-group
                    class="mb-3"
                    label="Jenis Asesmen"
                    v-if="seleceted_jenis_asesmen != '' && is_single_asesmen"
                    label-for="form-alat-test"
                  >
                    <b-form-input
                      id="form-alat-test"
                      class="bg-secondary text-white"
                      type="text"
                      readonly
                      v-model="jenis_asesmen_suksesi.asesmen_grade"
                      v-for="jenis_asesmen_suksesi in option_jenis_asesmen_suksesi"
                      :key="jenis_asesmen_suksesi.id"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>


          <div class="row col-12">
            <button
              class="btn btn-success mt-2 m-4"
              type="button"
              @click="validasi()"
            >
              <span><i class="fa fa-plus"></i> Submit Data </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
